import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntervalType } from 'src/app/models/IntervalType';
import { LeadList } from 'src/app/models/LeadList';
import { Tag } from 'src/app/models/Tag';
import { AuthService } from './auth.service';
import { Tag as TagAC } from 'src/app/models/ActiveCampaign';
import { environment } from './../../../environments/environment';
import { Sequence } from './../../models/Sequence';
import {CONEXAOPREMIUM, YAMPI, YEVER} from './integrations.service';
import {removeInvalidCharacters} from '../utils';
import { User } from 'src/app/models/User';

const tags: Tag[] = [
  { tag: '{first_name}', title: 'Primeiro nome do Lead' },
  { tag: '{email}', title: 'E-mail do Lead' },
  { tag: '{meu_link}', title: 'URL cadastrada nesta sequência!' },
  { tag: '{url_boleto}', title: 'Link para a URL do boleto' },
  { tag: '{data_vencimento}', title: 'Data de vencimento do boleto' },
  { tag: '{linha_digitavel}', title: 'Linha digitável do boleto' },
  { tag: '{url_checkout}', title: 'Link para a URL do checkout do produto' },
  { tag: '{url_pix}', title: 'Link para a URL do pix' },
  { tag: '{url_lista}', title: 'URL cadastrada nas listas manuais!' },
  { tag: '{url_membros}', title: 'URL da página de membros' },
  { tag: '{tracking}', title: 'Código de rastreio' },
  { tag: '{tracking_url}', title: 'URL de rastreio' },
  { tag: '{courier}', title: 'Nome da transportadora' },
  { tag: '{list}', title: 'Nome da Lista' },
  { tag: '{url_checkout_cross}', title: 'URL para checkout cross' },
  { tag: '{cupom}', title: 'Cupom cadastrado nesta sequẽncia!'},
  { tag: '{campaign}', title: 'Nome da Automação' },
  { tag: '{contentmsg}', title: 'Conteúdo definido no lead\nSubstitui todo o conteúdo da mensagem.'},
  { tag: '{dia_hj}', title: 'Dia de hoje' }
];

@Injectable({ providedIn: 'root' })
export class SequencesService {

  user: User;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  listByCampaignId(campaignId: string): Observable<Sequence[]> {
    return this.http.get<Sequence[]>(`${environment.apiUrl}/campaigns/${campaignId}/sequences`);
  }

  getTags(leadList: LeadList): Tag[] {
    let tagsToReturn = tags;

    this.user = this.authService.getUser()

    if (!this.user.content_tag_enabled) {
      tagsToReturn = tags.filter((tag) => {
        return !tag.tag.includes('contentmsg');
      })
    }
    
    if (
      leadList.integration?.platform_id !== YEVER &&
      leadList.integration?.platform_id !== YAMPI &&
      leadList.integration?.platform_id !== CONEXAOPREMIUM
    ) {
      return tagsToReturn.filter(t => !t.tag.includes('tracking') &&
        !t.tag.includes('courier') && !t.tag.includes('tracking_url'));
    }
    
    return tagsToReturn;
  }

  validateTags(sequence: Sequence, tagsToValidate: Tag[]): string[] {
    const regex = /\{([^}]+)\}/g;
    const matches = sequence.text.match(regex);
    const tagNames = tagsToValidate.map(t => t.tag);
    if (!matches) { return []; }

    const isInvalid = [];

    if (sequence)

    matches.forEach(m => {
      if (!tagsToValidate.map(t => t.tag).includes(m)) {
        isInvalid.push(m);
      }
    });

    return isInvalid;
  }


  findById(id: string): Observable<Sequence> {
    return this.http.get<Sequence>(`${environment.apiUrl}/sequences/${id}`);
  }

  save(sequence: Sequence | any): Observable<Sequence> {
    sequence.text ? removeInvalidCharacters(sequence.text) : ''
    sequence.call4u_campaign_description ? removeInvalidCharacters(sequence.call4u_campaign_description) : ''
    sequence.voxuy_campaign_description ? removeInvalidCharacters(sequence.voxuy_campaign_description) : ''
  
    if (sequence.id) {
      return this.update(sequence);
    }
    return this.create(sequence);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/sequences/${id}`);
  }

  updateStatus(sequence: Sequence): Observable<Sequence> {
    return this.http.put<Sequence>(`${environment.apiUrl}/sequences/${sequence.id}`, sequence);
  }

  listIntervalTypes(): Observable<IntervalType[]> {
    return this.http.get<IntervalType[]>(`${environment.apiUrl}/sequences/interval-types`);
  }

  getAcTags(sequenceId: string, campaignId: string): Observable<TagAC[][]> {
    return this.http.get<Array<TagAC[]>>(`${environment.apiUrl}/active-campaign/tags?sequenceId=${sequenceId}&campaignId=${campaignId}`);
  }

  private create(sequence: Sequence): Observable<Sequence> {
    return this.http.post<Sequence>(`${environment.apiUrl}/sequences`, sequence);
  }

  private update(sequence: Sequence): Observable<Sequence> {
    return this.http.put<Sequence>(`${environment.apiUrl}/sequences/${sequence.id}`, sequence);
  }
}
