import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, of } from 'rxjs';
import { Campaign } from 'src/app/models/Campaign';
import { LeadList } from 'src/app/models/LeadList';
import { Tag } from 'src/app/models/Tag';
import { urlValidatorService } from 'src/app/shared/validators/url.validator.service';
import { AuthService } from '../../../shared/services/auth.service';
import { User } from '../../../models/User';
import { BlacklistValidatorService } from '../../../shared/validators/blacklist.validator.service';
import { IntervalType } from './../../../models/IntervalType';
import { Sequence } from './../../../models/Sequence';
import { ConfirmationService } from './../../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from './../../../shared/components/toast/toast.service';
import { SequencesService } from './../../../shared/services/sequences.service';
import { Call4UService } from 'src/app/shared/services/call4u.service';
import { HOTMART ,APPMAX} from 'src/app/shared/services/integrations.service';
import { platformPatternValidator } from 'src/app/shared/validators/platformpattern.validator.service';
import { detectPlatformByPattern } from 'src/app/shared/validators/platformpattern.validator.service';
import { IntegrationActivationStatus } from 'src/app/models/IntegrationActivated';
import { Tag as AC_TAG } from 'src/app/models/ActiveCampaign';
import { ActiveCampaignService } from 'src/app/shared/services/active-campaign.service';
import { CopyService } from 'src/app/shared/services/copy.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sequences',
  templateUrl: './sequences.component.html',
  styleUrls: ['./sequences.component.scss']
})
export class SequencesComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('modalSequence', {static: true}) modalSequence: ElementRef<any>;
  @Input() form: UntypedFormGroup;
  @Input() campaign: Campaign;
  @Input() leadList: LeadList;
  @Input() sequences: Sequence[];

  @ViewChild('modalTag', {static: true}) modalTag: ElementRef<any>;

  modalTitle;

  tags: Tag[] = [];

  CALL4U_DOCS: string;

  startPosition = 0;
  endPosition = 0;
  messageMaxLength = 140;

  user: User;

  msgType: any = 1;
  sendLeadNumber = false;
  usedCall4U = false;

  intervalTypes: IntervalType[] = [];
  sequenceForm: UntypedFormGroup;

  AC_selected_tags: AC_TAG[] = []
  AC_preselected_all: AC_TAG[] = []
  AC_preselected_selected: AC_TAG[] = []
  AC_tags: AC_TAG[] = []

  tagForm: UntypedFormGroup;

  AC_Activated: boolean = false;

  tagMode: number = 1;
  tagInEdit?: AC_TAG;

  refreshAC: boolean = false;

  list_integration: string[] = [HOTMART,APPMAX]; // Futuramente transformaremos em uma lista
  have_list_integration: boolean; // Este retiraremos depois que for implantado em outras plataformas
  checkout_cross_platform: string | boolean;

  subscriptions: Subscription[] = [];

  dropDownMessage: boolean = false;

  constructor(
    private sequencesService: SequencesService,
    private modalService: NgbModal,
    private auth: AuthService,
    private toastService: ToastService,
    private formBuilder: UntypedFormBuilder,
    private blacklistValidator: BlacklistValidatorService,
    private confirmationService: ConfirmationService,
    private Call4UService: Call4UService,
    private ACService: ActiveCampaignService,
    private CopyService: CopyService
  ) {
    this.user = this.auth.getUser();
    if (this.user.call4u_user_id == null) {
      this.usedCall4U = false
    } else {
      this.usedCall4U = true
    }
    // this.usedCall4U = this.user.call4u_user_id == null ? true : false;
   }


  ngOnInit(): void {
    this.CALL4U_DOCS = environment.CALL4U_DOCS_2;
    this.sequenceForm = this.formBuilder.group({
      id: [''],
      active: [true],
      campaign_id: [this.campaign.id],
      interval: [0, Validators.required],
      text: ['', Validators.maxLength(this.messageMaxLength)],
      url: ['', urlValidatorService()],
      short_url: [''],
      interval_type_id: [1, Validators.required],

      coupon: ['', Validators.maxLength(250)],
      cross_checkout_url: ['', platformPatternValidator()],

      call4u_campaign_url: ['', urlValidatorService()],
      call4u_campaign_description: [''],

      voxuy_campaign_url: ['', urlValidatorService()],
      voxuy_campaign_description: ['']
    });

    this.tagForm = this.formBuilder.group({
      name: [Validators.required],
      description: ['']
    });

    this.subscriptions.push(
      this.sequencesService.listIntervalTypes().subscribe(intervalTypes => this.intervalTypes = intervalTypes)
    );
    this.Call4UService.isActivated().subscribe((response: IntegrationActivationStatus) => {
      this.usedCall4U = response.status
    });

    this.have_list_integration = this.campaign.lead_list && this.campaign.lead_list.integration 
                                    && this.campaign.lead_list.integration
                                    && this.campaign.lead_list.integration.platform 
                                    && this.list_integration.includes(this.campaign.lead_list.integration.platform.id);

    this.user = this.auth.getUser();
    if (this.user.call4u_user_id) {
      this.usedCall4U = true
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.leadList) {
      this.leadList = changes.leadList.currentValue;
      try {
        this.loadTags();        
      } catch {
        // Ignore
      }
    }

    if (changes.campaign) {
      this.campaign = changes.campaign.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadTags() {
    this.tags = this.sequencesService.getTags(this.leadList);
  }

  detectPlatformCrossCheckout() {
    const platform = detectPlatformByPattern(this.sequenceForm.get('cross_checkout_url').value);

    if (platform) {
      this.checkout_cross_platform = platform;
      return;
    }

    this.checkout_cross_platform = false;
  }

  toggleActive(sequence: Sequence): void {
    if (sequence.is_ac && !this.campaign.active_campaign) {
      this.toastService.error('Não é possível ativar essa sequência pois não há dados Active Campaign cadastrados.')
      this.listAll();
      return
    }

    sequence.active = !sequence.active;

    const successMessage = sequence.active
      ? 'Sequência ativada com sucesso!'
      : 'Sequência desativada com sucesso!';

    this.subscriptions.push(
      this.sequencesService.updateStatus(sequence).subscribe(() => this.toastService.success(successMessage))
    );
  }

  listAll(): void {
    this.subscriptions.push(
      this.sequencesService.listByCampaignId(this.campaign.id).subscribe(sequences => this.sequences = sequences)
    );
  }

  checkMsgType(sequence: Sequence = null, return_text: boolean = false) : number|string {
    
    if (!sequence) {
      sequence = this.sequenceForm.getRawValue() as Sequence;
    }
    
    if(sequence.call4u_campaign_url) {
      return return_text ? 'Mensagem de Voz' : 0 // Call4U
    }

    if (sequence.text) {
      return return_text ? 'Mensagem de SMS' : 1 // SMS
    }

    if (sequence.is_ac) {
      return return_text ? 'Active Campaign' : 2 // Active Campaign
    }

    if (sequence.voxuy_campaign_url) {
      return return_text ? 'Mensagem de WhatsApp' : 3 // Voxuy
    }

    return return_text ? 'Mensagem de SMS' : 1
  }

  getMsgTypeIcon(sequence: Sequence): string {
    const msgType = this.checkMsgType(sequence);
    
    switch(msgType) {
      case 0:
        return 'fa-phone'
      case 1:
      case 3:
        return 'fa-comment'
      case 2:
        return 'fa-tag'
    }
    
    return 'fa-comment'
  }

  addTag(tag: string): void {
    if (tag == '{contentmsg}') {
      this.confirmationService
      .show('Esta tag irá substituir todo o texto da mensagem, deseja adicioná-la mesmo assim?',
        () => {
          this.sequenceForm.patchValue({ text: tag });
        });
      return;
    }

    let text = this.sequenceForm.get('text').value || '';

    if (text.includes('{contentmsg}')) {
      text = text.replace('{contentmsg}', '');
      this.toastService.info('Tag {contentmsg} removida automaticamente.')
    }

    const size = text.length + `${tag}`.length;
    if (size < 150) {
      const beforeText = text.substring(0, this.startPosition);
      const afterText = text.substring(this.endPosition, text.length);

      text = beforeText + tag + afterText;

      this.sequenceForm.patchValue({ text });
    } else {
      this.toastService.error('Não há espaço suficiente para inserir esta TAG');
    }
  }

  new(modal: any): void {
    this.modalTitle = 'Nova ação';
    this.sequenceForm.reset();
    this.sequenceForm.patchValue({ campaign_id: this.campaign.id, active: true });

    this.msgType = this.checkMsgType();

    this.modalService.open(modal, { size: 'lg' });
    this.dropDownMessage = false;
    this.refreshACInfo();
  }

  edit(sequence: Sequence, modal: any): void {

    if (!this.campaign.lead_list_id) {
      return this.toastService.error('Por favor, cadastre uma lista à campanha para utilizar isto.');
    };

    this.sequenceForm.patchValue(sequence);
    this.sendLeadNumber = sequence.send_lead_number;

    this.msgType = this.checkMsgType(sequence);

    this.modalTitle = 'Alterar ação';
    this.sequenceForm.patchValue(sequence);
    this.modalService.open(modal, { size: 'lg' });
    this.dropDownMessage = false;
    this.refreshACInfo()

  }

  remove(sequence: Sequence): void {
    this.confirmationService
      .show('Deseja realmente excluir esta sequência?',
        () => {
          this.sequencesService
            .delete(sequence.id)
            .subscribe(
              () => {
                this.sequences = this.sequences.filter(s => s !== sequence);
                this.toastService.success('Sequência excluída com sucesso!');
              }
            );
        });
  }

  saveTags(modal: any): void {
    const sequence = this.sequenceForm.getRawValue();

    sequence.is_ac = true;
    sequence.ac_tags = this.AC_selected_tags;

    const successMessage = sequence.id
    ? 'Sequência atualizada com sucesso!'
    : 'Sequência inserida com sucesso!';

    const errorMessage = sequence.id
      ? 'Erro ao atualizar sequência.'
      : 'Erro ao inserir sequência.';

    this.sequencesService.save(sequence).subscribe(
      () => {
        this.toastService.success(successMessage);
        this.listAll();
        modal.close();
      },
      res => this.toastService.error(res?.error?.message || errorMessage));
  }

  saveCall4U(modal: any): void {
    const sequence = this.sequenceForm.getRawValue();

    sequence.url = null;
    sequence.text = null;
    sequence.short_url = null;
    sequence.voxuy_campaign_url = null;

    sequence.is_ac = false;
    sequence.ac_tags = [];
    if (!sequence.call4u_campaign_url || sequence.call4u_campaign_url === '') {
      return this.toastService.error('A URL da campanha é obrigatória.');
    }

    const successMessage = sequence.id
    ? 'Sequência atualizada com sucesso!'
    : 'Sequência inserida com sucesso!';

    const errorMessage = sequence.id
      ? 'Erro ao atualizar sequência.'
      : 'Erro ao inserir sequência.';


    this.subscriptions.push(
      this.sequencesService.save(sequence).subscribe(
        () => {
          this.toastService.success(successMessage);
          this.listAll();
          modal.close();
        },
        res => this.toastService.error(res?.error?.message || errorMessage))
      );
  }

  saveVoxuy(modal: any): void {
    const sequence = this.sequenceForm.getRawValue();

    sequence.text = null;
    sequence.url = null;
    sequence.short_url = null;
    sequence.call4u_campaign_url = null;

    if (!sequence.voxuy_campaign_url || sequence.voxuy_campaign_url === '') {
      return this.toastService.error('A URL da campanha é obrigatória.');
    }

    const successMessage = sequence.id
    ? 'Sequência atualizada com sucesso!'
    : 'Sequência inserida com sucesso!';

    const errorMessage = sequence.id
      ? 'Erro ao atualizar sequência.'
      : 'Erro ao inserir sequência.';


    this.subscriptions.push(
      this.sequencesService.save(sequence).subscribe(
        () => {
          this.toastService.success(successMessage);
          this.listAll();
          modal.close();
        },
        res => this.toastService.error(res?.error?.message || errorMessage))
      );
  }

  save(modal: any): void {
    const sequence = this.sequenceForm.getRawValue() as Sequence;
    sequence.send_lead_number = this.sendLeadNumber;

    if (this.msgType == 0) {
      return this.saveCall4U(modal)
    };

    if (this.msgType == 3) {
      return this.saveVoxuy(modal)
    };

    sequence.is_ac = false;

    sequence.ac_tags = [];

    const verifyFields = [sequence.text ?? null, sequence.call4u_campaign_url ?? null, this.AC_selected_tags ?? null, sequence.voxuy_campaign_url ?? null].filter(
      (i : any) => {
        if (i == null) {
          return false;
        }

        return i.toString() !== ""
      }
    );

    if (verifyFields.length > 1) {
      return this.toastService.error('Por favor, preencha apenas um tipo de mensagem por sequência.');
    }

    if (this.msgType === 0) {
      return this.saveCall4U(modal)
    }else if (this.msgType === 2) {
      return this.saveTags(modal)
    };

    if (sequence.text?.includes('{meu_link}') && !sequence.url?.trim()
    ) {
      this.toastService.error('Meu link é obrigatório quando você utiliza a TAG {meu_link}');
      return;
    }

    const error = this.blacklistValidator.validate(sequence.text);
    if (error) {
      this.toastService.error(error.message);
      return;
    }

    const invalidTags = this.sequencesService.validateTags(sequence, this.tags);
    if (invalidTags.length > 0) {
      this.toastService.error(`Você adicionou uma tag esta plataforma não fornece. ${invalidTags.join(',')}`);
      return;
    }

    const successMessage = sequence.id
      ? 'Sequência atualizada com sucesso!'
      : 'Sequência inserida com sucesso!';

    const errorMessage = sequence.id
      ? 'Erro ao atualizar sequência.'
      : 'Erro ao inserir sequência.';

    this.subscriptions.push(
      this.sequencesService.save(sequence).subscribe(
        () => {
          this.toastService.success(successMessage);
          this.listAll();
          modal.close();
        },
        res => this.toastService.error(res?.error?.message || errorMessage))
    );
  }

  setPosition(e: any) {
    this.startPosition = e.target.selectionStart;
    this.endPosition = e.target.selectionEnd;
  }

  close(activated: boolean) {
    if(activated) {
      this.modalService.dismissAll();
      const call4uHeader = document.body.querySelector('#call4u-header');
      call4uHeader.remove();
    };
  }

  newTag(tagModal: any): void {
    this.tagMode = 1;
    this.tagInEdit = null;
    this.tagForm.reset();
    this.tagForm.patchValue({ name: '' });

    this.modalService.open(tagModal, { size: 'lg' });
  }

  saveTag(modal: any): void {
    const name = this.tagForm.controls.name.getRawValue();
    const description = this.tagForm.controls.description.getRawValue();
    const sequence = this.sequenceForm.getRawValue() as Sequence;

    modal.close();

    if (this.tagMode === 1) {
      this.ACService.createTags(sequence.id, name, this.campaign.id, description).subscribe((response: AC_TAG) => {
        this.AC_tags.push(response);
      });
      this.toastService.success(`Tag "${name}" criada com sucesso!`)
      return;
    }

    let newTag = this.tagInEdit
    newTag.description = description
    newTag.tag = name

    this.ACService.editTags(sequence.id, newTag.id, newTag.tag, this.campaign.id, newTag.description).subscribe((response: any) => {
      if (response.statusCode === 200) {
        const i = this.AC_tags.indexOf(this.tagInEdit)
        this.AC_tags.splice(i, 1);
        this.AC_tags.splice(i, 0, newTag)

        this.toastService.success('Tag editada com sucesso!')
      }
    })
  };

  editTag(tagModal: any, tag: AC_TAG): void {
    this.tagMode = 0;
    this.tagInEdit = tag;
    this.tagForm.reset();
    this.tagForm.patchValue({ name: tag.tag ?? '' , description: tag.description ?? ''});
    this.modalService.open(tagModal, { size: 'lg' });
  }

  deleteTag(tag: AC_TAG): void {
    const sequence = this.sequenceForm.getRawValue() as Sequence;

    this.confirmationService.show(`Deseja realmente remover a tag "${tag.tag}"? <br>Esta ação pode ser irreversível.`, () => {
      this.ACService.deleteTags(sequence.id, tag.id, this.campaign.id).subscribe((response: any) => {
        if (response.statusCode === 200) {
          this.toastService.success(`Tag "${tag.tag}" deletada com sucesso!`);
          this.preSelectACTagAll(tag, false);
          this.preSelectACTagSelected(tag, false);
          return this.AC_tags.splice(this.AC_tags.indexOf(tag), 1)
        }
        return this.toastService.error('Ocorreu um problema ao remover esta tag... Por favor, tente novamente mais tarde.')
      })
    }, this.confirmationService.hide());
  }

  preSelectACTagAll(tag: AC_TAG, add: boolean = true): void {
    const index = this.AC_preselected_all.findIndex(item => item.id === tag.id);
    if (index !== -1) {
      this.AC_preselected_all.splice(index, 1);
    } else if (index === -1 && add) {
      this.AC_preselected_all.push(tag);
    }
  }

  preSelectACTagSelected(tag: AC_TAG, add: boolean = true): void {
    const index = this.AC_preselected_selected.findIndex(item => item.id === tag.id);
    if (index !== -1) {
      this.AC_preselected_selected.splice(index, 1);
    } else if (index === -1 && add) {
      this.AC_preselected_selected.push(tag);
    }
  }

  tagIsInPreselected(tag: AC_TAG): boolean {
    const indexAll = this.AC_preselected_all.findIndex(item => item.id === tag.id);
    const indexSelected = this.AC_preselected_selected.findIndex(item => item.id === tag.id);
    return indexAll !== -1 || indexSelected !== -1;
  }

  toAll(): void {
    let tags = [];
    this.AC_preselected_selected.forEach(tag => {
      const index = this.AC_selected_tags.indexOf(tag);
      if (!this.AC_tags.includes(tag)) {
        this.AC_selected_tags.splice(index, 1);
        this.AC_tags.push(tag);
      }
    });

    this.AC_preselected_selected = [];
  }

  toSelect(): void {
    let tags = [];
    this.AC_preselected_all.forEach(tag => {
      const index = this.AC_tags.indexOf(tag);
      if (!this.AC_selected_tags.includes(tag)) {
        this.AC_tags.splice(index, 1);
        tags.push(tag);
        this.AC_selected_tags.push(tag);
      }
    });

    this.AC_preselected_all = [];
  }

  refreshACInfo(notify: boolean = true): void {
    this.AC_tags = [];
    this.AC_selected_tags = [];

    const sequence = this.sequenceForm.getRawValue() as Sequence;
    this.refreshAC = true;

    if (!this.campaign.lead_list || !this.campaign.ac_infos) {
      return;
    }

    this.AC_Activated = this.campaign.ac_infos != null;

    if (!this.AC_Activated) {
      return;
    }

    if (notify) {
      this.toastService.info('Sincronizando informações do Active Campaign...')
    }

    this.sequencesService.getAcTags(sequence.id, this.campaign.id).subscribe((response: AC_TAG[][]) => {
      if (!response) {
        this.refreshACInfo(false);
        return;
      };
      this.AC_tags = response[0]
      this.AC_selected_tags = response[1]
    });

    setTimeout(() => this.refreshAC = false, 5000);
  }

  messageContentChanged(e) {
    let text = e.target.value || '';

    if (e.target.value.includes('{contentmsg}')) {
      text = text.replace('{contentmsg}', '');
      this.toastService.info('Tag {contentmsg} removida automaticamente.')
    }

    e.target.value = text;
  }
  activeDropdown() {
    this.dropDownMessage = !this.dropDownMessage;
  }

  copyPwd(): void {
    navigator.clipboard.writeText('newCallUser').then(() => {
      this.toastService.success('Senha copiada com sucesso!');
    })
  }
}
