import { ChangeDetectorRef, Component } from '@angular/core';

import { ToastService } from './shared/components/toast/toast.service';
import { AppService } from './shared/services/app.service';
import { ListsService } from './shared/services/lists.service';
import { BroadcastsService } from './shared/services/broadcasts.service';
import { BroadcastsWhatsappService } from './shared/services/broadcasts-whatsapp.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth.service';
import { User } from './models/User';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user: User;
  showBillingPopup: boolean = false;
  constructor(
    private appService: AppService,
    private listsService: ListsService,
    private broadcastsServices: BroadcastsService,
    private broadcastsWhatsappService: BroadcastsWhatsappService,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.getGTAG();
    this.appService.blackList().subscribe();
    this.listsService.startInterval().subscribe(updatedLeadList => {
      if (updatedLeadList.error) {
        this.toastService.error(`Erro ao importar lista ${updatedLeadList.name}. Verifique o arquivo e tente novamente!`);
      } else {
        this.toastService.success(`A importação da lista ${updatedLeadList.name} foi concluída!`);
      }
    });
  }

  ngOnInit() {
    setInterval(() => {
      this.user = this.authService.getUser();
      if (this.user && !this.user.billing_last_update) {
        this.showBillingPopup = true;
        const modal = document.getElementsByClassName('modal-billing');
        setTimeout(() => {
          if (modal.length == 0) {
            window.location.reload();
          }
        }, 2000);

      }else this.showBillingPopup = false;
    }, 1000)
  }

  // Função para dar setup no GTAG no HEAD dinâmicamente
  getGTAG() {
    if (!environment.G_TAG_ID) {
      return;
    }

    window.onload = () => {
      const meta = document.head.querySelector('meta');

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.G_TAG_ID}`;

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${environment.G_TAG_ID}');
      `;
      document.head.innerHTML = '<!-- Google tag (gtag.js) -->\n' + document.head.innerHTML;
      document.head.firstElementChild.insertAdjacentElement('beforebegin', script2);
      document.head.firstElementChild.insertAdjacentElement('beforebegin', script1);

    }
  }
}
